
<template>
    <div class="background_image" ref="content_div">
      <img style="opacity: 0" src="../../static/image_bg.jpg"  width="100%">
  
      <div class="login_div" v-bind:class="{'small_div':small}">
        <img src="../../static/home_title.png"  class="logo_image" />
        <div>
          <div>
            <p style="float: left;width: 18%; height: 2.5rem; text-align: right;margin:0px;margin-top: 0.7rem;">
              <img src="../../static/phone.png" height="19" width="14" style="margin-left: -0.50rem;line-height: 24px"/>
            </p>
            <p style="float: left;text-align: left; height: 2.5rem;width: 80%;margin:0px;margin-top: 0.4rem;">
              <input type="number"  v-model="phone_number" class="text_phone" @click="setScroll()"  placeholder="请输入手机号码">
              <span>&nbsp;&nbsp;&nbsp;</span>
            </p>
          </div>
          <div>
            <p style="float: left;width: 18%; height: 2.5rem; text-align: right;margin:0px;margin-top: 0.7rem;">
              <img src="../../static/code.png" height="19" width="14" style="margin-left: 0.5rem;margin-top: 0.2rem"/>
            </p>
            <p style="float: left;text-align: left; width: 80%;height: 2.5rem;width: 80%;margin:0px;margin-top: 0.55rem;">
              <input type="number" v-model="check_code" class="text_code" placeholder="请输入验证码">
              <span style="color: #FF5100;font-size: 0.85rem;margin-left: 0.3rem" @click="send_check_code()" v-show="show" >获取验证码</span>
              <span  style="font-size: 0.75rem;" v-show="!show" class="count">{{count}}秒重新获取</span>
            </p>
          </div>
        </div>
        <div class="btn_div" >
          <button type="button" class="btn" @click="register()"></button>
          <p class="checkbox_p">
            <input type="checkbox" @click="checkbox()" style="border: 0px;border-radius: 4px;margin-top: 2px;position: absolute;margin-left: -21px;" checked>
            <span>
              我已阅读并同意倾心之灵<a href="http://register.beibao.net.cn/yhxy.html" style="text-decoration: none; border-bottom: 1px solid #FFFFFF; ">《 用户协议 》</a>
            </span>
          </p>
        </div>
      </div>
  
      <!-- 提示框 -->
      <div>
        <div class="toast" v-show="toastShow">
          {{toastText}}
        </div>
      </div>
    </div>
  </template>
  
  <script type="text/babel">
  import axios from 'axios'
  export default {
    name: 'register',
    props: {
      msg: String
    },
    data() {
      return {
        small:false,
        toastShow: false,
        toastText: '',
        invite_code: '',
        platform: 2,
        phone_number: '',
        check_code: '',
        api_url:'http://register.beibao.net.cn/prod-api/',
        read_agreement: true,
        show: true,
        count: '',
        timer: null,
        channel: 'guanwang'
      }
    },
    mounted() {
      //判断是否为小屏手机
      let div_height = window.screen.availHeight;
      // 当屏幕小于680,输入手机号码时，屏幕向下滚动90px
      if (div_height < 680) {
        // 小屏幕手机
        this.small=true
      }
  
      this.invite_code = this.$route.query.invite_code
      this.channel = this.$route.query.channel
      let u = navigator.userAgent;
      let isIos = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
      // 如果是IOS微信内打开，直接跳转到落地页
      let ua = navigator.userAgent.toLowerCase();
      let isWeixin = ua.indexOf('micromessenger') != -1;
      if (isIos && isWeixin) {
        //target_url = "http://download.cqdate.com/"
        target_url = "https://www.beibao.net.cn/msg"
        
       // if (this.invite_code) {target_url = target_url + '?invite_code=' + this.invite_code}
        window.location.href = (target_url)
        return
      }
  
      if (isIos) {
        this.platform = 2
      } else {
        this.platform = 1
      }
  
      let params = new URLSearchParams()
      params.append('action',"open_web");
      params.append('channel',this.channel);
      params.append('inviteCode',this.invite_code);
      params.append('web',"register");
      params.append('platform',this.platform);
  
      // // 进行post 请求
      // let url = this.api_url + "/admin/web_action_statistics"
      
      // axios.post(url, params,{
      //   headers:{
      //     'Content-Type':'application/json',
      //     'client-info':'android/10/3.2.6/ALP-AL00/2a7612b10908c3ff',
      //     'auth-token':'123456'
      //   }
      // }).then(function (response) {
      //   console.log(response);
      // }).catch(function (error) {
      //   console.log(error);
      // });
      var target_url = ""
      //IOS打开到web端
      if (isIos) {
        //target_url = "https://web1.cqdate.com/"
        // target_url = "http://register.beibao.net.cn/msg"
           target_url = "http://register.beibao.net.cn/msg"
        // if (this.invite_code) {target_url += '?invite_code=' + this.invite_code}
        // if (this.channel) {
        //   if (target_url.indexOf('invite_code') != -1) {
        //     target_url += '?channel=' + this.channel
        //   } else {
        //     target_url += '&channel=' + this.channel
        //   }
        // }
        window.location.href = (target_url)
      }
    },
    methods: {
      checkbox(){
        this.read_agreement = event.target.checked
      },
      toast(str) {
        // 弹出框
        let v = this
        v.toastText = str
        v.toastShow = true
      
        //var target_url = 'http://download.cqdate.com?channel=guanwang'
        var target_url = 'http://register.beibao.net.cn/download?channel=guanwang'
        
        //if (this.channel) {target_url += '?channel=' + this.channel}
        setTimeout(function() {
          v.toastShow = false
          window.location.href = target_url
        }, 2000)
      },
      getCode(){
        const TIME_COUNT = 60;
        if (!this.timer) {
          this.count = TIME_COUNT;
          this.show = false;
          this.timer = setInterval(() => {
            if (this.count > 0 && this.count <= TIME_COUNT) {
              this.count--;
            } else {
              this.show = true;
              clearInterval(this.timer);
              this.timer = null;
            }
          }, 1000)
        }
      },
      send_check_code() {
        if (!this.phone_number) {
          alert('请输入手机号码')
          return;
        } else if (this.phone_number.length != 11) {
          alert('请输入正确的手机号码')
          return;
        }
        
       let params={
          'mobile': this.phone_number,
          'templateNo':'REGISTER_MOBILE',
          'inviteCode':this.invite_code,
          'channel':this.platform
        }
        // 发送短信
        let that = this
        let url = this.api_url +'/gateway/sendSms.htm'
        axios.post(url, params,{
        headers:{
          'Content-Type':'application/json',
          'client-info':this.channel,
          'auth-token':'123456'
        }
        }).then(function (response) {
          if (response.data.retCode !=100) {
            alert(response.data.retMessage);
          } else {
            // 倒计时效果
            that.getCode()
            
          }
        }).catch(function (error) {
          alert(error)
          console.log(error);
        });
      },
      register() {
        
        if (!this.phone_number) {
          alert('请输入手机号码')
          return
        } else if (this.phone_number.length != 11) {
          alert('请输入正确的手机号码')
          return
        } else if (!this.check_code) {
          alert('请输入验证码')
          return
        } else if (this.check_code.length != 4) {
          alert('请输入正确的验证码')
          return
        }else if (!this.read_agreement) {
          alert('请阅读并同意用户协议')
          return
        }
  
        // let params = new URLSearchParams()
        // params.append('inviteCode',this.invite_code);
        // params.append('channel', this.platform);
        // params.append('mobile', this.phone_number);
        // params.append('verifyCode', this.check_code);
        let params={
          'mobile': this.phone_number,
          'templateNo':'REGISTER_MOBILE',
          'inviteCode':this.invite_code,
          'verifyCode':this.check_code,
          'channel':this.platform
        }

        let that=this
        // 注册
        let url = this.api_url + "/gateway/verifyRegister.htm"
        axios.post(url, params,  {headers:{
          'Content-Type':'application/json',
          'client-info':this.channel,
          'auth-token':'123456'
        }
        }).then(function (response) {
          if (response.data.retCode == 100) {
            that.toast('注册成功!')
          } else {
            alert(response.data.retMessage)
          }
        }).catch(function (error) {
          console.log('网络错误:' + error)
        });
  
      },
  
      setScroll() {
        // 当屏幕小于680,输入手机号码时，屏幕向下滚动90px
        if(this.small){
          setTimeout(function () {
            window.scrollTo(0, 90)
          }, 500)
        }
      },
  
    }
  }
  
  </script>
  
  <style>
  .toast {
    position: fixed;
    z-index: 2000;
    left: 50%;
    top: 45%;
    transition: all .5s;
    -webkit-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    -o-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    text-align: center;
    border-radius: 5px;
    color: #FFF;
    background: rgba(17, 17, 17, 0.7);
    height: 45px;
    line-height: 45px;
    padding: 0 15px;
    max-width: 150px;
  }
  
  .background_image {
    text-align: center;
    background: url('../../static/image_bg.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    display: inline-block;
    width: 100%;
  }
  .btn{
    background: url("../../static/btn.png");
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    width: 90%;
    height: 4rem;
    border: 0;
  }
  
  .small_div{
    height: 38% !important;
  }
  
  .login_div{
    text-align: center;
    background: url("../../static/bg2.png");
    background-size: 100%;
    background-repeat: no-repeat;
    position: absolute;
    top: 3rem;
    width: 100%;
    height: 38%;
  }
  
  .checkbox_p
  {
    margin-top: 1.5rem;
    color:#FFFFFF ;
    font-size: 12px;
    line-height: 20px;
  }
  
  .logo_image {
    height: 25px;
    width: 126px;
    text-align: center;
    margin-top: 2.5rem;
  }
  .login_div>div>div{
    text-align: center;
    margin-top: 1.1rem;
    margin-left: 2.3rem;
    width: 80%;
    height: 2.9rem;
    border-radius:35px;
    background-color:#F6F8FA ;
  }
  
  .btn_div{
    width: 100%;
    position: absolute;
    bottom:8px
  }
  
  .text_phone{
    border: 0px;
    outline:none;
    cursor:pointer;
    font-size: 1rem;
    width: 150px;
    margin-left: 0.5rem;
    color:  black;
    height: 1.8rem;
    caret-color: #FF8800;
    background-color:#F6F8FA ;
  }
  .text_code{
    border: 0px;
    outline:none;
    cursor:pointer;
    font-size: 1rem;
    width: 7.2rem;
    margin-left: 0.5rem;
    color:  black;
    height: 1.8rem;
    caret-color: #FF8800;
    background-color:#F6F8FA ;
  }
  
  a:link :visited  {color:#FFFFFF;}
  a{color:#FFFFFF}
  
  input[type="checkbox"] {
    width: 0.8rem;
    height: 0.8rem;
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    line-height: 15px;
    margin-right: 10px;
    position: relative;
  }
  
  input[type="checkbox"]::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background: #e79e6b;
    width: 100%;
    height: 100%;
    border: 1px solid #FFFFFF;
    border-radius: 3px;
  }
  
  input[type="checkbox"]:checked::before {
    content: "\2713";
    background-color: #e79e6b;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    border: 1px solid #FFFFFF;
    border-radius:3px;
    color: #FFFFFF;
    font-size: 12px;
  }
  
  
  
  
  input::placeholder{
      color:#B9B9B9;
  }
  </style>
  